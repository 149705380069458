.radioSel {
  color: #fff;
}
.albumSel {
  background-color: none;
}
.singleSel {
  margin-top: 0;
}
.featureSel {
  cursor: pointer;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* sort variables */
:root {
  --radio: block;
  --album: block;
  --single: block;
  --feature: block;
  --main-bg: #da4f3a;
  --cursor-x: 0px;
  --cursor-y: 0px;
}

.mix {
  display: var(--radio);
}

.album {
  display: var(--album);
}

.single {
  display: var(--single);
}

.feature {
  display: var(--feature);
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
body {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  font-family: "Barlow", sans-serif;
}

html {
  background-color: var(--main-bg);
}

h1 {
  font-size: 2em;
  line-height: 1em;
}

.archivo,
h1 {
  font-family: "Archivo", sans-serif;
  font-weight: 900;
  font-stretch: 125%;
}

.etra-red-bg {
  background-color: var(--main-bg);
}

.etra-red {
  color: var(--main-bg);
}

.inverse {
  color: var(--main-bg);
  background-color: white;
}

.inverse-stay {
  color: var(--main-bg);
  background-color: white;
  opacity: 1 !important;
  z-index: 30434;
}

.postlist-label {
  writing-mode: vertical-rl;
  -webkit-writing-mode: vertical-rl;
}

/* TRANSITION */
.fade-enter {
  opacity: 0;
  transform: translate(0, 50px);
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translate(0, 0);

  transition: opacity 3000ms ease-out, transform 3000ms ease;
}
.fade-exit {
  opacity: 1;
}
.fade-exit.fade-exit-active {
  opacity: 0;
}

.list-choice {
  width: 100vw;
}

.only-small {
  background-color: var(--main-bg);
}

/*sm*/
@media (min-width: 640px) {
  #list,
  .list-choice {
    width: calc((2 / 5) * 100vw);
    display: block;
  }
  #content {
    width: calc((2 / 5) * 100vw);
  }
  .logo {
    height: calc((4 / 8) * 100vh);
    display: block;
  }
  .fade-enter {
    transform: translate(35px, 0);
  }
}

/*md*/
@media (min-width: 768px) {
  #list,
  .list-choice {
    width: calc((2 / 6) * 100vw);
  }
  #content {
    width: calc((3 / 6) * 100vw);
    max-width: calc((3 / 6) * 100vw);
  }
  .logo {
    height: calc((5 / 7) * 100vh);
  }
  .only-small {
    background-color: rgba(255, 255, 255, 0) !important;
  }
}

/*lg*/
@media (min-width: 1024px) {
  #list,
  .list-choice {
    width: calc((2 / 7) * 100vw);
  }
  #content {
    width: calc((4 / 7) * 100vw);
    max-width: calc((4 / 7) * 100vw);
  }
  .article-photo {
    max-width: 600px;
    width: 20vw;
    float: left;
  }
}

/*xl*/
@media (min-width: 1280px) {
}

/*2xl*/
@media (min-width: 1536px) {
}

p {
  margin-bottom: 0.5em;
}
iframe {
  margin-top: 1em;
  margin-bottom: 1em;
}
.youtube {
  aspect-ratio: 16/9;
  width: 100%;
}

.sort > p {
  cursor: pointer;
}

html,
p,
.nav,
.list-choice {
  transition: background-color 400ms, color 400ms;
}

p > a {
  text-decoration: underline;
}

.home-cover {
  height: 33vh;
  width: 100%;
}

.home-cover > img {
  transition: opacity 400ms;
  opacity: 1;
  z-index: -2;
}

.fade-hover {
  background-color: rgba(255, 255, 255, 0);
  transition: background-color 350ms;
}

.fade-hover:hover {
  background-color: rgba(256, 256, 256, 0.075);
}

.home-cover > img:hover {
  opacity: 0.8;
}

.ticker,
.ticker > div {
  height: 1.4em !important;
  background-color: #fff;
  color: var(--main-bg);
}

.height-rest {
  height: calc(100vh - 1.4em);
}

.hover-image {
  width: 15vw;
  position: fixed;
  z-index: 99999999;
  pointer-events: none;
  top: var(--cursor-y);
  left: var(--cursor-x);
  filter: url(#b);
}

img:not(.hover-image) {
  filter: url(#b);
  mix-blend-mode: luminosity;
}

body {
  overscroll-behavior-y: none;
}
